import { dashboardConfig } from "./dashboard";
import { errorPagesConfigs } from "./errorPages";
import { authRouteConfig } from "./auth";
import { initialUrl } from "../shared/constants/AppConst";
import { profileConfig } from "./profile";
import ComponentCore from "./ComponentCore/ComponentCore";
import Error403 from "./errorPages/Error403";
import Error404 from "./errorPages/Error404";
import { systemConfig } from "./system";
import { crmConfig } from "./crm";
import Button from "./ComponentCore/ButtonComponent";
import { ROUTER_PATH } from "shared/constants";
import { contactConfig } from "./Contacts/contact.route";
import { ordersConfig } from "./Orders/order.route";
import { pricesConfig } from "./Prices/price.route";

const authorizedStructure = {
  fallbackPath: ROUTER_PATH.LOGIN,
  unAuthorizedComponent: <Error403 />,
  routes: [...dashboardConfig,
  ...crmConfig,
  ...contactConfig,
  ...ordersConfig,
  ...pricesConfig,
    // ...profileConfig, ...systemConfig,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: "/component-core",
      element: <ComponentCore />,
    },
    {
      path: "*",
      exact: true,
      element: <Error404 />,
    },
  ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
