import { lazy } from "react";

const Prices = lazy(() => import("./index"));

export const pricesConfig = [
  {
    path: "/prices",
    element: <Prices />,
  },
];
